<template>
  <div class="events">
    <div class="container">
      <div class="events-banner" v-if="topActivity">
        <div class="banner-pic" :style="`background-image: url('${topActivity.coverImage}')`"></div>
        <div class="banner-content">
          <div class="title">{{ getText(topActivity, 'title') }}</div>
          <Tag :status="topActivity.status" />
          <div class="time">
            <b>{{ $t('events.StartTime') }}：</b>{{ $t('BeijingTime') }} {{ topActivity.startTime }}
          </div>
          <div class="url" v-if="topActivity.meetingUrl">
            <b>{{ $t('events.Url') }}：</b> <a :href="topActivity.meetingUrl" target="_blank">{{ topActivity.meetingUrl }}</a>
          </div>
          <div class="qrcode" v-if="topActivity.qrCodeUrl">
            <b>{{ $t('events.Qrcode') }}：</b> <img :src="topActivity.qrCodeUrl" width="80" />
          </div>
          <div class="desc">
            <b>{{ $t('events.Topic') }}：</b>{{ getText(topActivity, 'meetingTheme') }}
          </div>
          <EventButton :event="topActivity" />
        </div>
      </div>

      <ul class="category-nav" v-if="categoryList.length > 0">
        <li v-for="nav in categoryList" :key="nav.id">
          <a href="javascript:;" :class="{ active: nav.id == currentCategory.id }" @click="changeCategory(nav)">{{ getText(nav, 'name') }}</a>
        </li>
      </ul>

      <div class="event-list" v-if="list.length > 0">
        <el-row :gutter="16">
          <el-col :span="8" v-for="item in list" :key="item.id">
            <div class="item">
              <div class="item-pic" @click="toDetail(item.id)">
                <el-image style="width: 322px; height: 210px" :src="item.coverImage" fit="cover"></el-image>
                <Tag :status="item.status" />
              </div>
              <div class="title" @click="toDetail(item.id)">{{ getText(item, 'title') }}</div>
              <div class="time">{{ $t('events.StartTime') }}：{{ $t('BeijingTime') }} {{ item.startTime }}</div>
              <EventButton :event="item" type="primary" from="list" />
            </div>
          </el-col>
        </el-row>
        <el-pagination @current-change="getList" :current-page.sync="page" :page-size="size" background layout="prev, pager, next" :total="totalElements" :hide-on-single-page="true"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Tag from '@/components/Tag'
import EventButton from '@/components/EventButton'

export default {
  name: 'Events',
  components: {
    Tag,
    EventButton,
  },
  data() {
    return {
      size: 9,
      page: 1,
      totalElements: 0,
      list: [],
      currentCategory: null,
      topActivity: null,
      categoryList: [],
    }
  },
  watch: {
    $route() {
      this.getTopActivity()
      this.getCategoryList()
    },
  },
  created() {
    this.getTopActivity()
    this.getCategoryList()
  },
  methods: {
    getTopActivity() {
      api.topActivity().then((res) => {
        this.topActivity = res.data
      })
    },
    getCategoryList() {
      api.categoryList({ categoryTypes: ['ACTIVITY'] }).then((res) => {
        this.categoryList = res.data
        this.currentCategory = this.categoryList[0]
        this.page = 1
        this.getList()
      })
    },
    async getList() {
      const res = await api.activityPage(
        {
          categoryId: this.currentCategory.id,
        },
        `size=${this.size}&page=${this.page - 1}`
      )

      const { content, totalElements } = res.data
      this.list = content
      this.totalElements = totalElements
    },
    changeCategory(nav) {
      this.currentCategory = nav
      this.page = 1
      this.getList()
    },
    toDetail(id) {
      this.$router.push(`/event/${id}`)
    },
  },
}
</script>
